<template>
  <div class="lead-list-container">
    <b-row
      v-if="analytic"
      class="match-height"
    >

      <b-col
        cols="6"
        md="2"
        lg="2"
      >
        <card-statistic
          :title="t('Mới')"
          :value="analytic.newLead"
          icon="UsersIcon"
          color="primary"
        />
      </b-col>

      <b-col
        cols="6"
        md="2"
        lg="2"
      >
        <card-statistic
          :title="t('Hẹn xem')"
          :value="analytic.appointmentLead"
          icon="UsersIcon"
          color="secondary"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
        lg="2"
      >
        <card-statistic
          :title="t('Chăm sóc')"
          :value="analytic.takeCareLead"
          icon="UsersIcon"
          color="info"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
        lg="2"
      >
        <card-statistic
          :title="t('Đặt cọc')"
          :value="analytic.depositLead"
          icon="UsersIcon"
          color="warning"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
        lg="2"
      >
        <card-statistic
          :title="t('Thành công')"
          :value="analytic.successLead"
          icon="UsersIcon"
          color="success"
        />
      </b-col>
      <b-col
        cols="6"
        md="2"
        lg="2"
      >
        <card-statistic
          :title="t('Thất bại')"
          :value="analytic.failLead"
          icon="UsersIcon"
          color="danger"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Khách hẹn xem') }}</b-card-title>
        <crud-buttons
          :name="t('Khách hẹn xem')"
          :selected-rows="selectedRows"
          modal="modal-lead"
          :show-import="false"
          :show-export="true"
          :enable-add="$can('create', 'lead')"
          :hide-delete="!$can('delete', 'lead')"
          @on-add="resetItem"
          @on-delete="onDelete"
          @on-export="exportData"
          @on-import-file="importData"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <select-apartment v-model="apartment" />
          </b-col>
          <b-col>
            <select-lead-status
              v-model="leadStatus"
              :default-value="leadStatus"
              :label="null"
            />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              :placeholder="t('Tìm kiếm')"
              debounce="1000"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-lead-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Price -->
                <span v-else-if="props.column.field === 'name'">
                  <span>{{ props.row.sale && props.row.sale.user ? (props.row.sale.user.name + ' - ') : '' }}{{ props.row.apartment ? props.row.apartment.name : '' }} - {{ props.row.tenant ? props.row.tenant.name : props.row.name }}</span>
                </span>

                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    class="font-small-1 mr-50 mt-50"
                    :variant="`light-${props.row.leadStatusObject.variant}`"
                  >{{ props.row.leadStatusObject.title }}</b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="t('Khách hẹn xem')"
                    modal="modal-lead"
                    :hide-delete="!$can('delete', 'lead') || ['takeCare', 'deposit', 'success', 'fail'].includes(props.row.leadStatus)"
                    :hide-edit="!$can('update', 'lead') || ['takeCare', 'deposit', 'success', 'fail'].includes(props.row.leadStatus)"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  >
                    <template
                      v-if="$can('update', 'lead')"
                      slot="other-buttons"
                    >
                      <b-button
                        v-if="!['takeCare', 'deposit', 'success', 'fail'].includes(props.row.leadStatus)"
                        v-b-tooltip.hover.v-success
                        v-b-modal.modal-foward-lead
                        variant="success"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Chuyển tiếp')"
                        @click="onFowardItem(props.row)"
                      >
                        <feather-icon icon="FastForwardIcon" />
                      </b-button>

                      <b-button
                        v-if="props.row.leadStatus === 'takeCare'"
                        v-b-tooltip.hover.v-success
                        v-b-modal.modal-reservation
                        variant="success"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Đặt cọc')"
                        @click="onReservation(props.row)"
                      >
                        <feather-icon icon="FeatherIcon" />
                      </b-button>
                      <b-button
                        v-if="!['deposit', 'success', 'fail'].includes(props.row.leadStatus)"
                        v-b-tooltip.hover.v-warning
                        v-b-modal.modal-foward-lead
                        variant="warning"
                        class="btn-icon mr-50"
                        size="sm"
                        :title="t('Đánh dấu thất bại')"
                        @click="onMarkLeadAsFail(props.row)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </template>
                  </table-actions>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Modal -->
    <lead-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <!-- Modal -->
    <lead-detail-modal :lead-id="item.id" />
    <reservation-modal
      :item="reservationItem"
      @refetch-data="fetchData"
    />
    <forward-lead-modal
      :item="item"
      @refetch-data="fetchData"
    />

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BButton,
  VBModal,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import { VueGoodTable } from 'vue-good-table';
import CrudButtons from '@/views/components/CrudButtons.vue';
import TableActions from '@/views/components/TableActions.vue';
import CardStatistic from '@/views/components/CardStatistic.vue';
import SelectLeadStatus from '@/views/components/SelectLeadStatus.vue';
import { parseDateToString } from '@/auth/utils';
import LeadDetailModal from '../detail/LeadDetailModal.vue';
import useLeadList from './useLeadList';
import LeadModal from '../modal/LeadModal.vue';
import ReservationModal from '../../reservation/modal/ReservationModal.vue';
import ForwardLeadModal from '../forward/ForwardLeadModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    SelectLeadStatus,
    SelectApartment,
    VueGoodTable,
    TableActions,
    CrudButtons,
    LeadModal,
    CardStatistic,
    LeadDetailModal,
    ReservationModal,
    ForwardLeadModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    "b-modal": VBModal,
  },
  setup() {
    const {
      analytic,
      item,
      reservationItem,
      columns,
      rows,
      apartment,
      room,
      bed,
      leadStatus,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      isVmarket,
      fetchData,
      fetchLeads,
      deleteLeads,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
      resolveColWidthIfDisableBed,
      t,
      onFowardItem,
      markLeadFail,
      onReservation,
      onMarkLeadAsFail,
    } = useLeadList();

    return {
      analytic,
      item,
      reservationItem,
      columns,
      rows,
      apartment,
      room,
      bed,
      leadStatus,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      isVmarket,
      fetchData,
      fetchLeads,
      deleteLeads,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
      resolveColWidthIfDisableBed,
      t,
      parseDateToString,
      onFowardItem,
      markLeadFail,
      onReservation,
      onMarkLeadAsFail,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(lead) {
      const deleteObjects = lead && lead.id > 0 ? [lead] : this.selectedRows;
      this.deleteLeads(deleteObjects);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.lead-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
              min-width: 100px !important;
            }

            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(7) {
               min-width: 150px !important;
            }
            &:nth-child(8) {
               min-width: 240px !important;
            }
          }
        }
      }
    }
}
</style>
